import React, { useState, useEffect, useMemo, useCallback } from 'react';

import { api } from 'services/api';
import { toast } from 'shared/toast';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  ModalFooter,
  FormControl,
  FormLabel,
  FormErrorMessage,
} from '@chakra-ui/react';

import { ModalRootProps } from 'components/Modal/Root';
import OwnSelect from 'components/OwnSelect';

import { Locale, PriceTypes } from 'types/base';

type FormErrors = {
  [key: string]: string | boolean;
  type: 'invalid' | 'required';
};

interface ModalLocaleAndPriceTypeSelectProps extends ModalRootProps {
  data: {
    base_id: number | null;
  };
}

const ModalLocaleAndPriceTypeSelect: React.FC<ModalLocaleAndPriceTypeSelectProps> =
  ({ onConfirm, handleClose, data, ...restProps }) => {
    const { base_id } = data;

    const [baseLocales, setBaseLocales] = useState<Locale[]>([]);
    const [selectedBaseLocale, setSelectedBaseLocale] = useState<number | null>(
      null,
    );
    const [isLoadingBaseLocales, setIsLoadingBaseLocales] = useState(false);

    const [basePriceTypes, setBasePriceTypes] = useState<PriceTypes[]>([]);
    const [selectedBasePriceType, setSelectedBasePriceType] = useState<
      number | null
    >(null);
    const [isLoadingPriceTypes, setIsLoadingPriceTypes] = useState(false);

    const [errors, setErrors] = useState<FormErrors>({} as FormErrors);

    const isLoadingFilters = isLoadingBaseLocales || isLoadingPriceTypes;

    const getBaseLocales = useCallback(async (baseId: number | null) => {
      if (!baseId) {
        setBaseLocales([]);
        return;
      }

      try {
        setIsLoadingBaseLocales(true);
        const { data: response } = await api.get('locale', {
          params: {
            'filter[base_id]': baseId,
            sort: 'description',
          },
        });
        const baseLocalesLoaded = response.data;
        setBaseLocales(baseLocalesLoaded);

        if (baseLocalesLoaded.length === 1) {
          const [baseLocale] = baseLocalesLoaded;
          setSelectedBaseLocale(baseLocale.id);

          if (errors.locale_id) {
            setErrors((prevErrors) => {
              const newErrors = { ...prevErrors };
              delete newErrors.locale_id;
              return newErrors;
            });
          }
        }
      } catch (err) {
        toast({
          description:
            err.response?.data?.message ||
            'Houve um erro ao carregar os estados da base!',
          status: 'error',
        });
      } finally {
        setIsLoadingBaseLocales(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const baseLocalesToSelect = useMemo(() => {
      return baseLocales.map((locale) => ({
        value: String(locale.id),
        label: locale.description,
      }));
    }, [baseLocales]);

    useEffect(() => {
      getBaseLocales(base_id);
    }, [getBaseLocales, base_id]);

    const getBasePriceTypes = useCallback(async (baseId: number | null) => {
      if (!baseId) {
        setBasePriceTypes([]);
        return;
      }

      try {
        setIsLoadingPriceTypes(true);
        const { data: response } = await api.get('price/type', {
          params: {
            'filter[base_id]': baseId,
          },
        });
        const basePriceTypesLoaded = response.data;
        setBasePriceTypes(basePriceTypesLoaded);

        if (basePriceTypesLoaded.length === 1) {
          const [basePriceType] = basePriceTypesLoaded;
          setSelectedBasePriceType(basePriceType.id);

          if (errors.type_id) {
            setErrors((prevErrors) => {
              const newErrors = { ...prevErrors };
              delete newErrors.type_id;
              return newErrors;
            });
          }
        }
      } catch (err) {
        toast({
          description:
            err.response?.data?.message ||
            'Houve um erro ao carregar os tipos de preço da base!',
          status: 'error',
        });
      } finally {
        setIsLoadingPriceTypes(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const basePriceTypesToSelect = useMemo(() => {
      return basePriceTypes.map((type) => ({
        value: String(type.id),
        label: type.description,
      }));
    }, [basePriceTypes]);

    useEffect(() => {
      getBasePriceTypes(base_id);
    }, [getBasePriceTypes, base_id]);

    const handleConfirm = async (): Promise<void> => {
      const formErrors: FormErrors = {} as FormErrors;

      if (!selectedBaseLocale) {
        Object.assign(formErrors, { locale_id: true, type: 'required' });
      }

      if (!selectedBasePriceType) {
        Object.assign(formErrors, { type_id: true, type: 'required' });
      }

      setErrors(formErrors);
      if (Object.keys(formErrors).length) {
        return;
      }

      const locale = {
        id: selectedBaseLocale,
        key: baseLocales.find((l) => l.id === selectedBaseLocale)?.key,
        description: baseLocales.find((l) => l.id === selectedBaseLocale)
          ?.description,
      };

      const priceType = {
        id: selectedBasePriceType,
        key: basePriceTypes.find((t) => t.id === selectedBasePriceType)?.key,
        description: basePriceTypes.find((t) => t.id === selectedBasePriceType)
          ?.description,
      };

      if (onConfirm) onConfirm({ locale, priceType });
      handleClose();
    };

    return (
      <Modal {...restProps}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Selecionar Estado e Tipo de Preço</ModalHeader>

          <ModalCloseButton />

          <ModalBody>
            <FormControl isInvalid={!!errors.locale_id}>
              <FormLabel>Estado</FormLabel>

              <OwnSelect
                placeholder="Selecione"
                isInvalid={!!errors.locale_id}
                options={baseLocalesToSelect}
                value={baseLocalesToSelect.filter(
                  (s) => Number(s.value) === selectedBaseLocale,
                )}
                isLoading={isLoadingBaseLocales}
                isDisabled={
                  isLoadingBaseLocales || baseLocalesToSelect.length === 0
                }
                isClearable
                onChange={(selected) => {
                  const value = selected ? Number(selected.value) : null;

                  setSelectedBaseLocale(value);

                  if (value && errors.locale_id) {
                    setErrors((prevErrors) => {
                      const newErrors = { ...prevErrors };
                      delete newErrors.locale_id;
                      return newErrors;
                    });
                  }
                }}
              />

              <FormErrorMessage>
                {errors.locale_id && 'Estado é obrigatório.'}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.type_id} mt={4}>
              <FormLabel>Tipo de preço</FormLabel>

              <OwnSelect
                placeholder="Selecione"
                isInvalid={!!errors.type_id}
                options={basePriceTypesToSelect}
                value={basePriceTypesToSelect.filter(
                  (s) => Number(s.value) === selectedBasePriceType,
                )}
                isLoading={isLoadingPriceTypes}
                isDisabled={
                  isLoadingPriceTypes || basePriceTypesToSelect.length === 0
                }
                isClearable
                onChange={(selected) => {
                  const value = selected ? Number(selected.value) : null;

                  setSelectedBasePriceType(value);

                  if (value && errors.type_id) {
                    setErrors((prevErrors) => {
                      const newErrors = { ...prevErrors };
                      delete newErrors.type_id;
                      return newErrors;
                    });
                  }
                }}
              />

              <FormErrorMessage>
                {errors.type_id && 'Tipo é obrigatório.'}
              </FormErrorMessage>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              isLoading={isLoadingFilters}
              isDisabled={isLoadingFilters}
              colorScheme="green"
              onClick={handleConfirm}
            >
              Confirmar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };

export default ModalLocaleAndPriceTypeSelect;
