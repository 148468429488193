import React, { createContext, useState } from 'react';

import { useMediaQuery } from '@chakra-ui/react';

interface ISidebar {
  open: boolean;
  variant: 'sidebar';
  isMobile: boolean;
  isSticked: boolean;
  toggleSidebar(): void;
  toggleStick(): void;
  openSidebar(): void;
  closeSidebar(): void;
}

const SidebarContext = createContext({} as ISidebar);

export const SidebarProvider: React.FC = ({ children }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [isSticked, setIsSticked] = useState<boolean>(false);

  const [isMobile] = useMediaQuery('(max-width: 728px)');

  const toggleSidebar = (): void => {
    setOpen(!open);
  };

  const toggleStick = (): void => {
    setIsSticked(!isSticked);
  };

  const closeSidebar = (): void => setOpen(false);
  const openSidebar = (): void => setOpen(true);

  return (
    <SidebarContext.Provider
      value={{
        open,
        toggleSidebar,
        isMobile,
        isSticked,
        toggleStick,
        variant: 'sidebar',
        openSidebar,
        closeSidebar,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

export default SidebarContext;
